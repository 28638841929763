import $apollo from '@/graphql'
import { getListCoupon, getOneCoupon } from '@/graphql/coupon/queries.gql'
import { createCoupon, updateCoupon, deleteCoupon } from '@/graphql/coupon/mutations.gql'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  getListCoupon(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getListCoupon,
          variables: { input },
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          resolve(res.data.getListCoupon)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getOneCoupon(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getOneCoupon,
          variables: input,
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          const data = JSON.parse(JSON.stringify(res.data))
          resolve(data.getOneCoupon)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createCoupon(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: createCoupon,
          variables: { input },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.createCoupon)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateCoupon(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: updateCoupon,
          variables: { input },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.updateCoupon)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteCoupon(_, req) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: deleteCoupon,
          variables: req,
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.deleteCoupon)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
