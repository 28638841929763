import { ApolloClient, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'

const httpLink = new createUploadLink({
  uri: process.env.VUE_APP_BASE_GRAPHQL_URL,
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('jwt')

  return {
    headers: {
      ...headers,
      authorization: token ? token : '',
    },
  }
})

export default new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: {
    query: { fetchPolicy: 'network-only' },
  },
})
