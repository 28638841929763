import Vue from 'vue'
import Axios from 'axios'
import CompositionApi from '@vue/composition-api'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import InputAutowidth from 'vue-input-autowidth'

import App from './App.vue'
import store from './store'
import router from '@/router'
import vuetify from './plugins/vuetify'
import apolloClient from './graphql'
import VueApollo from 'vue-apollo'

Vue.use(VueApollo)

new VueApollo({
  defaultClient: apolloClient,
})

Vue.config.productionTip = false
Vue.prototype.$http = Axios

Vue.use(InputAutowidth)
Vue.use(CompositionApi)

Sentry.init({
  Vue,
  dsn: 'https://1b9ed977644c48b6a785e78353c4774b@o395187.ingest.sentry.io/5852286',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
})

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app')
