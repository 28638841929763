import $apollo from '@/graphql'
import { getListBanner, getOneBanner } from '@/graphql/banner/queries.gql'
import { createBanner, updateBanner, deleteBanner } from '@/graphql/banner/mutations.gql'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  getListBanner(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getListBanner,
          variables: input,
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          const data = JSON.parse(JSON.stringify(res.data))
          resolve(data.getListBanner)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getOneBanner(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getOneBanner,
          variables: input,
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          const data = JSON.parse(JSON.stringify(res.data))
          resolve(data.getOneBanner)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createBanner(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: createBanner,
          variables: { input },
          context: {
            hasUpload: true,
          },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.createBanner)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateBanner(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: updateBanner,
          variables: { input },
          context: {
            hasUpload: true,
          },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteBanner(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: deleteBanner,
          variables: input,
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
