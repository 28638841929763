import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: '대시보드',
    component: () => import('@/views/DashBoard.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/question',
    name: '질문 & 답변',
    component: () => import('@/views/Question.vue'),
  },
  {
    path: '/question/:uid',
    name: '질문 자세히보기',
    component: () => import('@/views/QuestionUpdate.vue'),
  },
  {
    path: '/reply',
    name: '추천답변',
    component: () => import('@/views/CoreReply.vue'),
  },
  {
    path: '/reply/create',
    name: '추천답변 등록',
    component: () => import('@/views/CoreReplyCreate.vue'),
  },
  {
    path: '/reply/:uid',
    name: '추천답변 수정',
    component: () => import('@/views/CoreReplyUpdate.vue'),
  },
  {
    path: '/column',
    name: '칼럼',
    component: () => import('@/views/Column.vue'),
  },
  {
    path: '/column/recommend',
    name: '추천칼럼',
    component: () => import('@/views/Column.vue'),
  },
  {
    path: '/column/create',
    name: '칼럼 등록',
    component: () => import('@/views/ColumnCreate.vue'),
  },
  {
    path: '/column/:uid',
    name: '칼럼 수정',
    component: () => import('@/views/ColumnUpdate.vue'),
  },
  {
    path: '/column/recommend/:uid',
    name: '추천칼럼 수정',
    component: () => import('@/views/ColumnUpdate.vue'),
  },
  {
    path: '/report',
    name: '레포트',
    component: () => import('@/views/Report.vue'),
  },
  {
    path: '/report/category',
    name: '레포트 분류',
    component: () => import('@/views/ReportCategory.vue'),
  },
  {
    path: '/report/create',
    name: '레포트 등록',
    component: () => import('@/views/ReportCreate.vue'),
  },
  {
    path: '/report/:uid',
    name: '레포트 수정',
    component: () => import('@/views/ReportUpdate.vue'),
  },
  {
    path: '/notice',
    name: '공지사항',
    component: () => import('@/views/Notice.vue'),
  },
  {
    path: '/notice/create',
    name: '공지사항 추가',
    component: () => import('@/views/NoticeCreate.vue'),
  },
  {
    path: '/notice/:uid',
    name: '공지사항 수정',
    component: () => import('@/views/NoticeUpdate.vue'),
  },
  {
    path: '/mentor',
    name: '멘토',
    component: () => import('@/views/Mentor.vue'),
  },
  {
    path: '/mentor/create',
    name: '멘토 등록',
    component: () => import('@/views/MentorCreate.vue'),
  },
  {
    path: '/mentor/:uid',
    name: '멘토 수정',
    component: () => import('@/views/MentorUpdate.vue'),
  },
  {
    path: '/carousel',
    name: '컨텐츠 캐러셀',
    component: () => import('@/views/Carousel.vue'),
  },
  {
    path: '/carousel/create',
    name: '캐러셀 등록',
    component: () => import('@/views/CarouselCreate.vue'),
  },
  {
    path: '/carousel/:uid',
    name: '캐러셀 수정',
    component: () => import('@/views/CarouselUpdate.vue'),
  },
  {
    path: '/daily_letter',
    name: '멘토의 매일 편지',
    component: () => import('@/views/DailyLetter.vue'),
  },
  {
    path: '/daily_letter/create',
    name: '편지 추가',
    component: () => import('@/views/DailyLetterCreate.vue'),
  },
  {
    path: '/daily_letter/:uid',
    name: '편지 수정',
    component: () => import('@/views/DailyLetterUpdate.vue'),
  },
  {
    path: '/faq',
    name: '자주 묻는 질문',
    component: () => import('@/views/FAQ.vue'),
  },
  {
    path: '/faq/create',
    name: '자주 묻는 질문 추가',
    component: () => import('@/views/FAQCreate.vue'),
  },
  {
    path: '/faq/:uid',
    name: '자주 묻는 질문 수정',
    component: () => import('@/views/FAQInfo.vue'),
  },
  {
    path: '/group',
    name: '유저 그룹',
    component: () => import('@/views/Group.vue'),
  },
  {
    path: '/group/create',
    name: '유저 그룹 추가',
    component: () => import('@/views/GroupCreate.vue'),
  },
  {
    path: '/group/:name',
    name: '유저 그룹 수정',
    component: () => import('@/views/GroupInfo.vue'),
  },
  {
    path: '/invisible-tag',
    name: '투명 라벨 관리',
    component: () => import('@/views/InvisibleTag.vue'),
  },
  {
    path: '/recommend_search',
    name: '추천 검색어',
    component: () => import('@/views/RecommendSearch.vue'),
  },
  {
    path: '/recommend-question',
    name: '추천 질문',
    component: () => import('@/views/RecommendQuestion.vue'),
  },
  {
    path: '/notification',
    name: '메시지 목록',
    component: () => import('@/views/Notification.vue'),
  },
  {
    path: '/notification/create',
    name: '메시지 보내기',
    component: () => import('@/views/NotificationCreate.vue'),
  },
  {
    path: '/notification/:pushid',
    name: '메시지 수정',
    component: () => import('@/views/NotificationUpdate.vue'),
  },
  {
    path: '/banner/hakhak',
    name: '학학이 배너',
    component: () => import('@/views/Banner.vue'),
    props: { type: 'hakhak' },
  },
  {
    path: '/banner/home',
    name: '연구소 배너',
    component: () => import('@/views/Banner.vue'),
    props: { type: 'home' },
  },
  {
    path: '/banner/reply',
    name: '광고 배너',
    component: () => import('@/views/Banner.vue'),
    props: { type: 'reply' },
  },
  {
    path: '/banner/main',
    name: '초기 팝업',
    component: () => import('@/views/Banner.vue'),
    props: { type: 'main' },
  },
  {
    path: '/banner/hakhak/create',
    name: '학학이 배너 추가',
    component: () => import('@/views/BannerCreate.vue'),
    props: { type: 'hakhak' },
  },
  {
    path: '/banner/home/create',
    name: '연구소 배너 추가',
    component: () => import('@/views/BannerCreate.vue'),
    props: { type: 'home' },
  },
  {
    path: '/banner/reply/create',
    name: '광고 배너 추가',
    component: () => import('@/views/BannerCreate.vue'),
    props: { type: 'reply' },
  },
  {
    path: '/banner/main/create',
    name: '초기 팝업 배너 추가',
    component: () => import('@/views/BannerCreate.vue'),
    props: { type: 'main' },
  },
  {
    path: '/banner/hakhak/:id',
    name: '학학이 배너 수정',
    component: () => import('@/views/BannerUpdate.vue'),
    props: { type: 'hakhak' },
  },
  {
    path: '/banner/home/:id',
    name: '연구소 배너 수정',
    component: () => import('@/views/BannerUpdate.vue'),
    props: { type: 'home' },
  },
  {
    path: '/banner/reply/:id',
    name: '광고 배너 수정',
    component: () => import('@/views/BannerUpdate.vue'),
    props: { type: 'reply' },
  },
  {
    path: '/banner/main/:id',
    name: '초기 팝업 배너 수정',
    component: () => import('@/views/BannerUpdate.vue'),
    props: { type: 'main' },
  },
  {
    path: '/fish',
    name: '생선',
    component: () => import('@/views/Fish.vue'),
  },
  {
    path: '/coupon',
    name: '쿠폰 목록',
    component: () => import('@/views/Coupon.vue'),
  },
  {
    path: '/coupon/create',
    name: '쿠폰 등록',
    component: () => import('@/views/CouponCreate.vue'),
  },
  {
    path: '/coupon/:uid',
    name: '쿠폰 수정',
    component: () => import('@/views/CouponUpdate.vue'),
  },
  { path: '*', name: '404', component: () => import('@/views/404.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
