<template>
  <v-navigation-drawer app permanent width="250" expand-on-hover>
    <v-navigation-drawer app permanent class="text-center" mini-variant>
      <v-menu v-model="menu" :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on }">
          <img
            :src="require('@/assets/right.svg')"
            :style="`position: absolute; z-index: 1; transition: 0.3s; pointer-events: none; top: 24px; left: ${active ? '-24px' : '-48px'};`"
          />
          <v-avatar
            class="mt-4"
            color="deep-purple accent-1"
            style="cursor: pointer"
            size="36"
            v-on="on"
            @mouseover="active = true"
            @mouseleave="active = false"
          >
            <h1 class="white--text">
              {{ username[0] }}
            </h1>
          </v-avatar>
        </template>
        <v-list dense>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="deep-purple accent-1">mdi-weather-night</v-icon>
            </v-list-item-icon>
            <v-list-item-title>테마 설정</v-list-item-title>
            <v-list-item-action-text style="white-space:nowrap">
              시스템 설정과 동기화
            </v-list-item-action-text>
            <v-list-item-action class="mr-5">
              <v-switch v-model="syncSystemTheme" color="deep-purple accent-1" @click="changeTheme(null)" />
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <img height="75" :src="require('../assets/light_preview.svg')" style="border-radius: 4px" class="ma-2" @click="changeTheme('light')" />
            <img height="75" class="ma-2" :src="require('../assets/dark_preview.svg')" style="border-radius: 4px" @click="changeTheme('dark')" />
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon color="red accent-1">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>로그아웃</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-divider class="mx-3 my-5" />

      <draggable :list="first" :animation="300">
        <div v-for="x in first" :key="x.key" style="position: relative">
          <div
            :style="
              `position: absolute; width: 5px; border-radius: 0px 4px 4px 0px; transition: 0.1s; background: ${
                $vuetify.theme.dark ? 'white' : '#9146ff'
              }; margin: ${x.to === second.to ? '3' : '10'}px 0px; height: ${x.to === second.to ? '30' : '16'}px; width: ${
                x.to === second.to || x.mouse ? '5' : '0'
              }px`
            "
          />
          <v-avatar
            :style="`cursor: pointer; transition: 0.1s; ${x.mouse ? 'border-radius: 12px' : ''}`"
            class="mb-5"
            size="36"
            :color="x.color"
            :rounded="x.to === second.to"
            @click="$router.push(x.to)"
            @mouseover="
              x.mouse = true
              second = x
            "
            @mouseleave="x.mouse = false"
          >
            <v-icon color="white">mdi-{{ x.icon }}</v-icon>
          </v-avatar>
        </div>
      </draggable>
    </v-navigation-drawer>

    <v-sheet class="pl-14" :color="second.color" height="128" width="100%">
      <v-layout align-center justify-center fill-height style="white-space: nowrap">
        <h1 class="white--text">
          <v-avatar size="36" color="white">
            <v-icon :color="second.color">mdi-{{ second.icon }}</v-icon>
          </v-avatar>
          {{ second.name }}
        </h1>
      </v-layout>
    </v-sheet>

    <v-list class="pl-14" flat>
      <v-list-group
        v-for="x in second.expand"
        :key="x.key"
        v-model="x.active"
        :color="second.color"
        :append-icon="x.expand ? '$expand' : null"
        @click="$router.push(x.to)"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ x.name }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="y in x.expand" :key="y.key" class="pl-8" :to="y.to" exact>
          <v-list-item-content>
            <v-list-item-title v-text="y.name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { defineComponent, reactive, onBeforeMount, watch, toRefs } from '@vue/composition-api'
import draggable from 'vuedraggable'
import { mapState } from 'vuex'
import axios from 'axios'

export default defineComponent({
  components: {
    draggable,
  },

  computed: {
    ...mapState('app', ['username']),
  },

  setup(props, { root }) {
    const state = reactive({
      syncSystemTheme: !localStorage.getItem('theme'),
      active: false,
      menu: false,
      second: {},
      first: [
        {
          name: '대시보드',
          to: '/',
          icon: 'chart-timeline-variant-shimmer',
          color: 'red accent-1',
          mouse: false,
          expand: [],
        },
        {
          name: '질문',
          to: '/question',
          icon: 'help',
          color: 'pink lighten-3',
          mouse: false,
          expand: [],
        },
        {
          name: '추천답변',
          to: '/reply',
          icon: 'android-messages',
          color: 'deep-purple accent-1',
          mouse: false,
        },
        {
          name: '칼럼',
          to: '/column',
          icon: 'email-newsletter',
          color: 'blue accent-1',
          mouse: false,
        },
        {
          name: '레포트',
          to: '/report',
          icon: 'email',
          color: '#66BB6A',
          mouse: false,
          expand: [
            {
              name: '레포트',
              to: '/report',
            },
            {
              name: '레포트 분류 관리',
              to: '/report/category',
            },
          ],
        },
        {
          name: '게시판 관리',
          to: '/notice',
          icon: 'exclamation-thick',
          color: 'light-green lighten-2',
          mouse: false,
          expand: [
            {
              name: '공지사항',
              to: '/notice',
            },
            {
              name: '자주묻는질문',
              to: '/faq',
            },
          ],
        },
        {
          name: '유저 관리',
          to: '/notification',
          icon: 'account',
          color: 'amber accent-3',
          mouse: false,
          expand: [
            {
              name: '앱 푸시',
              to: '/notification',
            },
            {
              name: '유저 그룹화',
              to: '/group',
            },
          ],
        },
        {
          name: '전시 관리',
          to: '/banner/hakhak',
          icon: 'application',
          color: 'orange lighten-2',
          mouse: false,
          expand: [
            {
              name: '배너',
              to: '/banner/hakhak',
            },
            {
              name: '추천 검색어',
              to: '/recommend_search',
            },
            {
              name: '추천 질문',
              to: '/recommend-question',
            },
            {
              name: '매일 편지',
              to: '/daily_letter',
            },
            {
              name: '콘텐츠 캐러셀',
              to: '/carousel',
            },
          ],
        },
        {
          name: '이벤트 관리',
          to: '/fish',
          icon: 'ticket-percent',
          color: 'brown lighten-1',
          mouse: false,
          expand: [
            {
              name: '생선',
              to: '/fish',
            },
            {
              name: '쿠폰 관리',
              to: '/coupon',
            },
          ],
        },
        {
          name: '관리자 메뉴',
          to: '/mentor',
          icon: 'wrench',
          color: 'grey lighten-1',
          mouse: false,
          expand: [
            {
              name: '멘토',
              to: '/mentor',
            },
            {
              name: '라벨 관리',
              to: '/invisible-tag',
            },
          ],
        },
      ],
    })

    const logout = () => {
      state.menu = false
      localStorage.removeItem('jwt')
      axios.defaults.headers.common = {}
      root.$store.dispatch('app/setAuthentication', {
        jwt: null,
        username: null,
      })
      root.$router.push('/login')
    }

    const setPointer = () => {
      state.first.forEach((x) => {
        if (x.to === root.$route.path) state.second = x
        if (x.expand)
          x.expand.forEach((y) => {
            if (y.to === root.$route.path) {
              state.second = x
              y.active = true
            }
            if (y.expand)
              y.expand.forEach((z) => {
                if (z.to === root.$route.path) {
                  state.second = x
                  y.active = true
                }
              })
          })
      })
    }

    const changeTheme = (theme) => {
      const dark = window.matchMedia('(prefers-color-scheme: dark)')
      if (theme) {
        root.$vuetify.theme.dark = theme === 'dark' ? true : false
        localStorage.setItem('theme', theme)
        state.syncSystemTheme = false
      } else if (state.syncSystemTheme) {
        root.$vuetify.theme.dark = dark.matches
        localStorage.removeItem('theme')
      } else {
        localStorage.setItem('theme', dark.matches ? 'dark' : 'light')
      }
    }

    onBeforeMount(async () => {
      setPointer()
    })

    watch(() => root.$route.path, setPointer)

    watch(
      () => state.first,
      () => {
        state.first.forEach((x) => {
          x.mouse = false
        })
      },
    )

    return {
      ...toRefs(state),
      logout,
      changeTheme,
    }
  },
})
</script>
