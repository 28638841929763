import $apollo from '@/graphql'
import { addFishToUser } from '@/graphql/fish/mutations.gql'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  addFishToUser(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: addFishToUser,
          variables: { input },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.addFishToUser)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
