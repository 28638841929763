import $apollo from '@/graphql'
import { getListCarousel, getCarousel } from '@/graphql/carousel/queries.gql'
import { createCarousel, updateCarousel, deleteCarousel } from '@/graphql/carousel/mutations.gql'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  getListCarousel(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getListCarousel,
          variables: { input },
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          const data = JSON.parse(JSON.stringify(res.data))
          resolve(data.getListCarousel)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getCarousel(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getCarousel,
          variables: { id: input.id },
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          resolve(res.data.getCarousel)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createCarousel(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: createCarousel,
          variables: input,
          context: {
            hasUpload: true,
          },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.createCarousel)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateCarousel(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: updateCarousel,
          variables: input,
          context: {
            hasUpload: true,
          },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteCarousel(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: deleteCarousel,
          variables: input,
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
