import $apollo from '@/graphql'
import { getListMentor, getOneMentor } from '@/graphql/mentor/queries.gql'
import { createMentor, updateMentor } from '@/graphql/mentor/mutations.gql'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  getListMentor(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getListMentor,
          variables: { input },
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          resolve(res.data.getListMentor)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getOneMentor(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getOneMentor,
          variables: input,
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          const data = JSON.parse(JSON.stringify(res.data))
          resolve(data.getOneMentor)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createMentor(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: createMentor,
          variables: { input },
          context: {
            hasUpload: true,
          },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.createMentor)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateMentor(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: updateMentor,
          variables: { input },
          context: {
            hasUpload: true,
          },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.updateMentor)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
