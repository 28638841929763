import $apollo from '@/graphql'
import { getListPopup, getOnePopup } from '@/graphql/popup/queries.gql'
import { createPopup, updatePopup, deletePopup } from '@/graphql/popup/mutations.gql'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  getListPopup(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getListPopup,
          variables: input,
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          resolve(res.data.getListPopup)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getOnePopup(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getOnePopup,
          variables: input,
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          const data = JSON.parse(JSON.stringify(res.data))
          resolve(data.getOnePopup)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createPopup(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: createPopup,
          variables: { input },
          context: {
            hasUpload: true,
          },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.createPopup)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updatePopup(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: updatePopup,
          variables: { input },
          context: {
            hasUpload: true,
          },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.updatePopup)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deletePopup(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: deletePopup,
          variables: input,
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
