import $apollo from '@/graphql'
import { getListFaq, getOneFaq } from '@/graphql/faq/queries.gql'
import { createFaq, updateFaq, deleteFaq } from '@/graphql/faq/mutations.gql'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  getListFaq(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getListFaq,
          variables: { input },
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          resolve(res.data.getListFaq)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getOneFaq(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getOneFaq,
          variables: { id: input.id },
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          const data = JSON.parse(JSON.stringify(res.data))
          resolve(data.getOneFaq)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createFaq(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: createFaq,
          variables: { input },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.createFaq)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateFaq(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: updateFaq,
          variables: { input },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteFaq(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: deleteFaq,
          variables: { id: input.id },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
