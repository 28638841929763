import $apollo from '@/graphql'
import { getListReport, getOneReport, getListReportCategory } from '@/graphql/report/queries.gql'
import { createReport, updateReport, deleteReport, createReportCategory, updateReportCategory, deleteReportCategory } from '@/graphql/report/mutations.gql'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  getListReport(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getListReport,
          variables: { input },
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          resolve(res.data.getListReport)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getOneReport(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getOneReport,
          variables: {
            id: input.id,
          },
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          const data = JSON.parse(JSON.stringify(res.data))
          resolve(data.getOneReport)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createReport(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: createReport,
          variables: { input },
          context: {
            hasUpload: true,
          },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.createReport)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateReport(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: updateReport,
          variables: { input },
          context: {
            hasUpload: true,
          },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteReport(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: deleteReport,
          variables: { id: input.id },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getListReportCategory(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getListReportCategory,
          variables: input,
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          resolve(res.data.getListReportCategory)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createReportCategory(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: createReportCategory,
          variables: { input },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.createReportCategory)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateReportCategory(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: updateReportCategory,
          variables: { input },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteReportCategory(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: deleteReportCategory,
          variables: input,
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
