<template>
  <v-app v-show="mounted">
    <NavigationDrawer v-if="jwt" />
    <v-main :style="$vuetify.theme.dark ? '' : 'background: #F8F8F8'">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, toRefs } from '@vue/composition-api'
import NavigationDrawer from '@/components/NavigationDrawer.vue'
import { mapState } from 'vuex'
import axios from 'axios'
import JwtDecode from 'jwt-decode'

export default defineComponent({
  components: { NavigationDrawer },
  computed: {
    ...mapState('app', ['jwt']),
  },
  setup(props, { root }) {
    const state = reactive({
      mounted: false,
    })

    onBeforeMount(async () => {
      const dark = window.matchMedia('(prefers-color-scheme: dark)')

      if (localStorage.getItem('theme')) root.$vuetify.theme.dark = localStorage.getItem('theme') === 'dark' ? true : false
      else root.$vuetify.theme.dark = dark.matches

      dark.addEventListener('change', () => {
        if (!localStorage.getItem('theme')) root.$vuetify.theme.dark = dark.matches
      })

      let jwt = localStorage.getItem('jwt')
      let username = localStorage.getItem('username')

      if (jwt) {
        const token = jwt.replace('Bearer ', '')
        const validateToken = JwtDecode(token)

        if (validateToken.exp < new Date().getTime() * 0.001) {
          localStorage.removeItem('jwt')
          root.$router.go()
        }
      }

      root.$store
        .dispatch('app/authenticate', { jwt: jwt })
        .then(async () => {
          let authentication = { username: username, jwt: jwt }
          root.$store.dispatch('app/setAuthentication', authentication)
          console.log('🎉 Auth', authentication)

          axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*'
          axios.defaults.headers.common['Authorization'] = `${root.jwt}`

          if (root.$router.currentRoute.path === '/login') {
            root.$router.push('/')
          }
        })
        .catch(() => {
          localStorage.removeItem('jwt')

          console.log('🚧 No auth data')

          if (root.$router.currentRoute.path !== '/login') {
            root.$router.push('/login')
          }
        })
        .finally(() => {
          state.mounted = true
        })
    })

    return {
      ...toRefs(state),
    }
  },
})
</script>

<style lang="scss">
@import 'styles/index';
</style>
