import $apollo from '@/graphql'
import { getNewStamp, getListInvisibleStamp } from '@/graphql/stamp/queries.gql'
import { createInvisibleStamp, updateInvisibleStamp, deleteInvisibleStamp } from '@/graphql/stamp/mutations.gql'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  getNewStamp(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getNewStamp,
          variables: { input },
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          resolve(res.data.getNewStamp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getListInvisibleStamp() {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getListInvisibleStamp,
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          const data = JSON.parse(JSON.stringify(res.data))
          resolve(data.getListInvisibleStamp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createInvisibleStamp(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: createInvisibleStamp,
          variables: { input },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.createInvisibleStamp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateInvisibleStamp(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: updateInvisibleStamp,
          variables: { input },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.updateInvisibleStamp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteInvisibleStamp(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: deleteInvisibleStamp,
          variables: input,
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
