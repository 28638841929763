import { get, set } from '@/utils/vuex'
import axios from 'axios'
import $apollo from '@/graphql'
import { getEducation, getMiddleSchoolKind, getHighSchoolKind } from '@/graphql/queries'

const state = {
  education: [],
  middleSchoolKind: [],
  highSchoolKind: [],
}

const getters = {
  education: get('education'),
  middleSchoolKind: get('middleSchoolKind'),
  highSchoolKind: get('highSchoolKind'),
}

const mutations = {
  setEducation: set('education'),
  setMiddleSchoolKind: set('middleSchoolKind'),
  setHighSchoolKind: set('highSchoolKind'),
}

const actions = {
  updateUser(_, req) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${process.env.VUE_APP_BASE_URL}/users/${req.uid}`, req)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getEducation({ commit }) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getEducation,
        })
        .then((res) => {
          commit('setEducation', res.data.__type.enumValues)
          console.log('🔍 Query', res.data.__type)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getMiddleSchoolKind({ commit }) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getMiddleSchoolKind,
        })
        .then((res) => {
          commit('setMiddleSchoolKind', res.data.__type.enumValues)
          console.log('🔍 Query', res.data.__type)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getHighSchoolKind({ commit }) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getHighSchoolKind,
        })
        .then((res) => {
          commit('setHighSchoolKind', res.data.__type.enumValues)
          console.log('🔍 Query', res.data.__type)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
