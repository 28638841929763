import $apollo from '@/graphql'
import { getOneCoreReply, getListCoreReply } from '@/graphql/coreReply/queries.gql'
import { createCoreReply, deleteCoreReply, updateCoreReply, changeCoreReplyStamp, changeCoreReplyInvisibleStamp } from '@/graphql/coreReply/mutations.gql'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  getOneCoreReply(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getOneCoreReply,
          variables: { id: input.id },
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          const data = JSON.parse(JSON.stringify(res.data))
          resolve(data.getOneCoreReply)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getListCoreReply(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getListCoreReply,
          variables: { input },
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          resolve(res.data.getListCoreReply)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createCoreReply(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: createCoreReply,
          variables: { input },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.createCoreReply)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateCoreReply(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: updateCoreReply,
          variables: { input },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.updateCoreReply)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteCoreReply(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: deleteCoreReply,
          variables: input,
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve()
        })
        .catch((err) => {
          console.log(err)
          alert(err)
          reject()
        })
    })
  },
  changeCoreReplyStamp(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: changeCoreReplyStamp,
          variables: { input },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.changeCoreReplyStamp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  changeCoreReplyInvisibleStamp(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: changeCoreReplyInvisibleStamp,
          variables: { input },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.changeCoreReplyInvisibleStamp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
