import { get, set } from '@/utils/vuex'
import $apollo from '@/graphql'
import axios from 'axios'
import { getListUser, getListAbility, getDashBoard } from '@/graphql/admin/queries.gql'

const state = {
  color: 'warning',
  jwt: null,
  username: null,
  invisibleStamps: null,
  adminAccountId: null,
  abilities: null,
}

const getters = {
  invisibleStamps: get('invisibleStamps'),
  abilities: get('abilities'),
}

const mutations = {
  setColor: set('color'),
  setJwt: set('jwt'),
  setUsername: set('username'),
  setInvisibleStamps: set('invisibleStamps'),
  setAdminAccountId: set('adminAccountId'),
  setAbilities: set('abilities'),
}

const actions = {
  login({ commit }, req) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_BASE_URL + '/login', req)
        .then((res) => {
          commit('setJwt', res.data.jwt)
          commit('setUsername', res.data.username)
          commit('setAdminAccountId', res.data.adminAccountId)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  authenticate(_, req) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_BASE_URL + '/authenticate', req)
        .then(() => resolve())
        .catch(() => reject())
    })
  },
  setUsername({ commit }, username) {
    commit('setUsername', username)
  },
  setAuthentication({ commit }, req) {
    commit('setJwt', req.jwt)
    commit('setUsername', req.username)
  },
  getListUser(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getListUser,
          variables: { input },
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          resolve(res.data.getListUser)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getListAbility({ commit }) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getListAbility,
        })
        .then((res) => {
          commit('setAbilities', res.data.getListAbility)
          console.log('🔍 Query', res.data)
          resolve(res.data.getListAbility)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getDashBoard() {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getDashBoard,
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          resolve(res.data.getDashBoard)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
