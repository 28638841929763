import $apollo from '@/graphql'
import { getListNotification, getOneNotification } from '@/graphql/notification/queries.gql'
import { notification, reNotification, cancelNotification } from '@/graphql/notification/mutations.gql'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  getListNotification(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getListNotification,
          variables: { input },
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          resolve(res.data.getListNotification)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getOneNotification(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getOneNotification,
          variables: { id: input.id },
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          const data = JSON.parse(JSON.stringify(res.data))
          resolve(data.getOneNotification)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  notification(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: notification,
          variables: { input },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  reNotification(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: reNotification,
          variables: { input },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  cancelNotification(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: cancelNotification,
          variables: { id: input.id },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
