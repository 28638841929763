import $apollo from '@/graphql'
import { getListLetterOfMentor } from '@/graphql/letterOfMentor/queries.gql'
import { createLetterOfMentor, updateLetterOfMentor, deleteLetterOfMentor } from '@/graphql/letterOfMentor/mutations.gql'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  getListLetterOfMentor(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getListLetterOfMentor,
          variables: { input },
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          const data = JSON.parse(JSON.stringify(res.data))
          resolve(data.getListLetterOfMentor)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createLetterOfMentor(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: createLetterOfMentor,
          variables: input,
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.createLetterOfMentor)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateLetterOfMentor(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: updateLetterOfMentor,
          variables: input,
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.updateLetterOfMentor)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteLetterOfMentor(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: deleteLetterOfMentor,
          variables: input,
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.deleteLetterOfMentor)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
