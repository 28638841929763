import gql from 'graphql-tag'

export const getEducation = gql`
  query type {
    __type(name: "Education") {
      enumValues {
        name
      }
    }
  }
`

export const getMiddleSchoolKind = gql`
  query type {
    __type(name: "MiddleSchoolKind") {
      enumValues {
        name
      }
    }
  }
`

export const getHighSchoolKind = gql`
  query type {
    __type(name: "HighSchoolKind") {
      enumValues {
        name
      }
    }
  }
`
