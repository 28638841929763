import { get, set } from '@/utils/vuex'
import $apollo from '@/graphql'
import { getListGroup, getOneGroup } from '@/graphql/group/queries.gql'
import { createGroup, updateGroup, deleteGroup } from '@/graphql/group/mutations.gql'

const state = {
  group: null,
  groups: [],
}

const getters = {
  group: get('group'),
  groups: get('groups'),
}

const mutations = {
  setGroup: set('group'),
  setGroups: set('groups'),
}

const actions = {
  getListGroup(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getListGroup,
          variables: { input },
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          resolve(res.data.getListGroup.Items)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getOneGroup(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getOneGroup,
          variables: input,
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          const data = JSON.parse(JSON.stringify(res.data))
          resolve(data.getOneGroup)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createGroup(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: createGroup,
          variables: { input },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateGroup(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: updateGroup,
          variables: { input },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteGroup(_, req) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: deleteGroup,
          variables: req,
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
