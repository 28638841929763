import $apollo from '@/graphql'
import { getListRecommendSearch } from '@/graphql/recommendSearch/queries.gql'
import { createRecommendSearch, updateRecommendSearch, deleteRecommendSearch } from '@/graphql/recommendSearch/mutations.gql'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  getListRecommendSearch() {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getListRecommendSearch,
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          const data = JSON.parse(JSON.stringify(res.data))
          resolve(data.getListRecommendSearch)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createRecommendSearch(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: createRecommendSearch,
          variables: { input },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.createRecommendSearch)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateRecommendSearch(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: updateRecommendSearch,
          variables: { input },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteRecommendSearch(_, req) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: deleteRecommendSearch,
          variables: req,
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve()
        })
        .catch((err) => {
          console.log(err)
          alert(err)
          reject()
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
