import $apollo from '@/graphql'
import { createNotice, updateNotice, deleteNotice } from '@/graphql/notice/mutations.gql'
import { getOneNotice, getListNotice } from '@/graphql/notice/queries.gql'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  getOneNotice(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getOneNotice,
          variables: {
            id: input.id,
          },
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          const data = JSON.parse(JSON.stringify(res.data))
          resolve(data.getOneNotice)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getListNotice(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getListNotice,
          variables: { input },
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          resolve(res.data.getListNotice)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createNotice(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: createNotice,
          variables: { input },
          context: {
            hasUpload: true,
          },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.createNotice)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateNotice(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: updateNotice,
          variables: { input },
          context: {
            hasUpload: true,
          },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.updateNotice)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteNotice(_, req) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: deleteNotice,
          variables: req,
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
