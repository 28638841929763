import $apollo from '@/graphql'
import { getListRecommendQuestion } from '@/graphql/recommendQuestion/queries.gql'
import { createRecommendQuestion, updateRecommendQuestion, deleteRecommendQuestion } from '@/graphql/recommendQuestion/mutations.gql'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  getListRecommendQuestion(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .query({
          query: getListRecommendQuestion,
          variables: { input },
        })
        .then((res) => {
          console.log('🔍 Query', res.data)
          const data = JSON.parse(JSON.stringify(res.data))
          resolve(data.getListRecommendQuestion)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createRecommendQuestion(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: createRecommendQuestion,
          variables: { input },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.createRecommendQuestion)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateRecommendQuestion(_, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: updateRecommendQuestion,
          variables: { input },
        })
        .then((res) => {
          console.log('🚀 Mutation', res.data)
          resolve(res.data.updateRecommendQuestion)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteRecommendQuestion({ commit }, input) {
    return new Promise((resolve, reject) => {
      $apollo
        .mutate({
          mutation: deleteRecommendQuestion,
          variables: input,
        })
        .then((res) => {
          commit('setRecommendAnswer', res.data.deleteRecommendQuestion)
          console.log('🚀 Mutation', res.data)
          resolve(res.data.deleteRecommendQuestion)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
